.dropzone-container {
  width: 6rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid var(--ppt-blue);
  border-radius: 0.2rem;
}

.dropzone-container:hover {
  border-color: var(--ppt-red);
}

.dropzone-container-drag {
  border: 0.2rem dashed var(--ppt-blue);
}

.dropzone-text {
  font-size: 0.8rem;
  text-align: center;
}

.dropzone-display {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.3;
  transition: 0.2s;
  pointer-events: none;
}

.dropzone-active .dropzone-display {
  opacity: 1;
}

.dropzone-photos {
  padding: 0.4rem;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  align-items: center;
}
