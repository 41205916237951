.theme-pipeline {
  --primary-brand-color: #888;
  --primary-brand-color-25: #88888842;

  --lightbox-color: #888888af;
  --secondary-brand-color: #222;
  --secondary-brand-color-25: #22222242;

  --accent-color-dark: #000;
  --accent-color-light: #fff;
  --text-color-contrast: #eee;
  --secondary-contrast: #eee;
}

.theme-keller-williams {
  --primary-brand-color: #b40101;
  --primary-brand-color-25: #b4010142;
  --secondary-brand-color: #990000;
  --secondary-brand-color-25: #99000042;

  --secondary-contrast: #eee;
  --accent-color-dark: #000;
  --accent-color-light: #efefde;
}

.theme-century-21 {
  --primary-brand-color: #404041;
  --primary-brand-color-25: #40404142;

  --secondary-brand-color: #beae89;
  --secondary-brand-color-25: #beae8942;

  --secondary-contrast: rgb(49, 49, 49);

  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-sothebys {
  --primary-brand-color: #002349;
  --primary-brand-color-25: #00234942;

  --secondary-brand-color: #999999;
  --secondary-brand-color-25: #99999942;
  --secondary-contrast: rgb(49, 49, 49);

  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-remax {
  --primary-brand-color: #e11b22;
  --primary-brand-color-25: #e11b2242;

  --lightbox-color: #e11b22ad;

  --secondary-brand-color: #0054a4;
  --secondary-brand-color-25: #0054a442;
  --secondary-contrast: #eee;

  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-berkshire {
  --primary-brand-color: #552448;
  --primary-brand-color-25: #55244842;

  --secondary-brand-color: #eae3d4;
  --secondary-brand-color-25: #eae3d442;
  --secondary-contrast: rgb(49, 49, 49);

  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-coldwell {
  --primary-brand-color: #003366;
  --primary-brand-color-25: #00336642;

  --secondary-brand-color: #003366;
  --secondary-brand-color-25: #00336642;
  --secondary-contrast: rgb(197, 197, 197);

  --accent-color-dark: #000;
  --accent-color-light-50: #fff;
}

.theme-exit-realty {
  --primary-brand-color: #008c9a;
  --primary-brand-color-25: #008c9a42;

  --secondary-brand-color: #98002e;
  --secondary-brand-color-25: #98002e42;
  --secondary-contrast: #eee;

  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-weichert {
  --primary-brand-color: #000000;
  --primary-brand-color-25: #00000042;

  --secondary-brand-color: #f9d71c;
  --secondary-brand-color-25: #f9d71c42;
  --secondary-contrast: rgb(49, 49, 49);

  --accent-color-dark: #000;
  --accent-color-light-50: #fff;
}

.theme-era {
  --primary-brand-color: #e31937;
  --primary-brand-color-25: #e3193742;

  --secondary-brand-color: #273691;
  --secondary-brand-color-25: #27369142;
  --secondary-contrast: #eee;

  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-exp {
  --primary-brand-color: #19469d;
  --primary-brand-color-25: #19469d42;

  --secondary-brand-color: #000000;
  --secondary-brand-color-25: #00000042;
  --secondary-contrast: #eee;

  --accent-color-dark: #f5821f;
  --accent-color-light: #f9a81a;
}
