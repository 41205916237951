@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
  --popup-shadow: rgb(99, 99, 99, 0.41);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

html {
  --dark-bg: #161925;
  --med-bg: #23395b;
  --light-bg: #242734;
  --light-bg-alt: #293246;

  --alt-bg: #dfdfdf;
  --alt-bg-dark: #b1b1b1;

  --dark-text: #111;
  --grey-text: #666;
  --light-text: #fff;
  --ppt-red: #ea1543;
  --ppt-red-30: #ea15434d;

  --ppt-blue: #0060ad;
  --ppt-success: #2fa97f;
  --ppt-caution: #f98d17;
  --ppt-grey: #5b5b5b;
  --ppt-glassblue: #0064b669;
  --ppt-darkgreen: #009623;
  --headline-font: "Poppins", sans-serif;
  --display-font: "Open Sans", sans-serif;
  transition: 0.15s;
  transition-timing-function: ease-in-out;
}

.darkmode {
  --dark-bg: #000000;
  --med-bg: #3c485f;
  --light-bg: #223549;
  --alt-bg: #e4e4e4;
  --dark-text: #111;
  --light-text: rgb(224, 224, 224);
}

body {
  margin: 0;
  font-family: var(--display-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--dark-bg),
    var(--med-bg),
    var(--light-bg)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right bottom,
    var(--dark-bg),
    var(--med-bg),
    var(--light-bg)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: var(--light-text);
  background-attachment: fixed;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--headline-font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-2xs {
  font-size: 0.60rem; 
  line-height: 0.65rem;
}

#root-app {
  display: grid;
  grid-template-areas:
    "nav main main"
    "message main main";
  grid-template-columns: 0.4fr 2fr 2fr;
  grid-template-rows: 22rem 2fr;
  grid-column-gap: 2rem;
  height: 100vh;
  width: 100vw;
}

#root-login,
#root-signup {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

#login-credentials,
#signup-information {
  width: 35%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  padding: 2rem;
  z-index: 10;
  position: relative;
}

#login-actions,
#signup-actions {
  height: 35%;
  min-width: 6rem;
  z-index: 1;
  background: var(--alt-bg);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  transform: translateX(-2rem);
  padding: 2rem 2rem 2rem 4rem;
}

#login-input {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex: auto;
}

#login-message {
  position: absolute;
  bottom: 1rem;
  opacity: 0.8;
  transition: 0.2s;
  transform: scale(1.05);
}

#header {
  grid-area: header;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  width: calc(100%-4rem);
  position: relative;
}

#header-nav-tab {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

#sidebar-nav {
  grid-area: nav;
  margin: 0 0 0 2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1rem;
}

#messages {
  background: transparent;
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0 2rem;
}

.header-nav-item {
  margin: 0 0.5rem;
  border-bottom: 3px solid transparent;
  transition: 0.2s;
  cursor: pointer;
}

.header-nav-item:hover {
  border-bottom-color: var(--ppt-blue);
}

.header-nav-active {
  border-bottom-color: var(--ppt-red);
  background: var(--med-bg);
}

.sidebar-nav-item {
  padding: 0.2rem 0.4rem;
  border-right: 4px solid transparent;
  transition: 0.1s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.sidebar-nav-item:hover {
  border-right-color: var(--ppt-blue);
}

.sidebar-nav-active {
  border-right-color: var(--ppt-red);
  background: var(--alt-bg-dark);
  color: var(--dark-text);
}

#nav-actions {
  position: absolute;
  top: 0;
  right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 10;
}

#account-button {
  color: var(--ppt-blue);
}

#logout-button,
#account-button {
  transition: 0.15s;
  margin-right: 0.2rem;
  padding-right: 0.4rem;
  border-right: 3px solid transparent;
}

#account-button:hover,
#logout-button:hover {
  border-right: 3px solid var(--ppt-red);
}

.ppt-logo {
  height: 2rem;
  margin: 1rem;
}

.bg-dark-alternating {
  background-color: var(--light-bg);
}

.bg-dark-alternating:nth-child(even) {
  background-color: var(--light-bg-alt);
}

#main {
  grid-area: main;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: stretch;
  justify-content: flex-start;
  margin: 0 4rem 0 0;
  perspective: 800px;
  position: relative;
  overflow: visible;
  overflow-y: auto;
  padding: 5rem 0 0 0;
}

*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: var(--light-bg);
  border-radius: 5px;
  opacity: 0.8;
}

*::-webkit-scrollbar-thumb {
  background: var(--ppt-blue);
  border-radius: 5px;
  opacity: 0.8;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--ppt-red);
}

#sidebar {
  grid-area: sidebar;
  margin: 0 0 0 2rem;
}

.sidebar-toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.box-shadow-subtle {
  -webkit-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
}

.bg-overlay {
  background-color: rgb(17, 24, 39, 0.8);
}

.ppt-input {
  box-sizing: border-box;
  height: 2.4rem;
  min-width: 50%;
  background: var(--alt-bg);
  font-size: 1.1rem;
  padding: 0 0.5rem;
  -webkit-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  position: relative;
  border-right: 0.4rem solid var(--ppt-red);
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  z-index: 5;
}

.ppt-input-container {
  min-height: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transform-origin: 50% 50%;
  margin: 0 1rem;
}

.ppt-input-label {
  color: var(--light-text);
  background: transparent;
  min-height: 2rem;
  padding: 0.3rem;
  z-index: 1;
  font-size: 0.8rem;
  transform-origin: 50% 50%;
  transform: translate(0.2rem, 0.5rem);
  opacity: 0.85;
  font-weight: 600;
}

.ppt-checkbox {
  display: block;
  margin: 10px 0;
  position: relative;
  background: var(--ppt-blue);
  box-sizing: border-box;
}

.ppt-checkbox-label {
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: var(--dark-text);
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  box-sizing: border-box;
}

.ppt-checkbox-label::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: var(--ppt-blue);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.ppt-checkbox-label::after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 3px 4px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}

.ppt-checkbox .ppt-checkbox-input:checked ~ .ppt-checkbox-label {
  color: var(--alt-bg);
}

.ppt-checkbox .ppt-checkbox-input:checked ~ .ppt-checkbox-label::before {
  -webkit-transform: translate(-50%, -50%) scale3d(77, 77, 1);
  transform: translate(-50%, -50%) scale3d(77, 77, 1);
  opacity: 1;
}

.ppt-checkbox .ppt-checkbox-input:checked ~ .ppt-checkbox-label::after {
  background-color: var(--ppt-success);
  border-color: var(--ppt-success);
}

.ppt-checkbox-input {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.ppt-select-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: stretch;
  justify-content: flex-start;
  background: var(--alt-bg-dark);
}
.ppt-select-toggle-button {
  position: absolute;
  height: 2rem;
  width: 2rem;
  bottom: 0.2rem;
  right: -0.5rem;
  background: transparent;
  color: var(--ppt-blue);
}

.ppt-select-menu-item {
  margin: 0.1rem 0;
  width: 100%;
  border-right: 5px solid transparent;
  list-style-type: none;
  padding: 0 0.5rem;
  box-sizing: border-box;
  transition: 0.1s;
  transition-timing-function: ease-in-out;
}
.ppt-select-menu-item:hover {
  border-right: 5px solid var(--ppt-blue);
  background: var(--alt-bg);
}
.ppt-select-menu-active {
  border-right: 5px solid var(--ppt-red);
}

.ppt-card {
  z-index: 10;
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  background: var(--alt-bg);
  color: var(--dark-bg);
}

.ppt-actions-left {
  z-index: 1;
  padding: 0.3rem;
  padding-left: 1.3rem;
  transform: translateX(-1rem);
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.w25 {
  width: 25%;
}

.w33 {
  width: 33%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.center-content-flex {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-actions-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.panel-content {
  min-height: 10rem;
  min-width: 50%;
  margin: 0;
  padding: 0.5rem;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center-even {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flex-center-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.flex-left-column {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.flex-row-space-evenly {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.flex-row-space-between {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.flex-row-space-around {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.flex-center-space-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.flex-row-center {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.flex-table {
  display: flex;
  flex-wrap: wrap;
  margin: 0.2rem 0;
  padding: 0.2rem;
}

.flex-table-item {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.2rem;
  overflow: hidden;
  list-style: none;
}

.align-center {
  align-items: center;
}

.flex-table-2col .flex-table-item {
  width: 50%;
}

.flex-table-3col .flex-table-item {
  width: 33.33%;
}

.flex-table-4col .flex-table-item {
  width: 25%;
}

.flex-table-5col .flex-table-item {
  width: 20%;
}

.flex-table-6col .flex-table-item {
  width: 16.6%;
}

.flex1 {
  flex: 1;
}

.flex0 {
  flex: 0;
}

.ppt-table-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  text-overflow: ellipsis;
}

.ppt-table-dark {
  background: var(--light-bg);
  color: var(--light-text);
}

.ppt-table-dark:nth-child(even) {
  background: var(--light-bg-alt);
}

.h20rem {
  height: 20rem;
}

.overflow-scroll {
  min-height: 0;
  overflow-y: scroll;
}

.padding-1rem {
  padding: 1rem;
}

.padding-2remtop {
  padding-top: 2rem;
}

.button-hover-right-red {
  border-right: 3px solid transparent;
  transition: 0.15s;
  padding-right: 0.4rem;
  transition-timing-function: ease-in;
}

.button-right-selected {
  border-right: 3px solid var(--ppt-red);
}

.button-hover-right-red:hover {
  border-right: 3px solid var(--ppt-blue);
}

.transform-up-half-rem {
  transform: translateY(-0.5rem);
}

.rounded-corner {
  border-radius: 1rem;
}

.margin1rem-top {
  margin-top: 1rem;
}

.margin-1rem-right {
  margin-right: 1rem;
}

.margin-icon {
  margin: 0.2rem;
}

.w5rem {
  width: 5rem;
}

.w7rem {
  width: 7rem;
}

.w9rem {
  width: 9rem;
}

.w10rem {
  width: 10rem;
}

.h10rem {
  height: 10rem;
}

.w12rem {
  width: 12rem;
}

.h12rem {
  height: 12rem;
}

.w16rem {
  width: 16rem;
}

.w20rem {
  width: 20rem;
}

.h20rem {
  height: 20rem;
}

.w30rem {
  width: 30rem;
}

.w42rem {
  width: 42rem;
}

.w40percent {
  width: 40%;
}

.w50percent {
  width: 50%;
}

.w70percent {
  width: 70%;
}

.w82percent {
  width: 82%;
}

.w90percent {
  width: 90%;
}

.w100percent {
  width: 100%;
}

.margin1rem {
  margin: 1rem;
}

.margin-reset {
  margin: 0;
}

.position-rel {
  position: relative;
}

.text-small {
  font-size: 0.9rem;
}

.circle {
  border-radius: 100%;
}

.border1rem {
  border: 1rem solid var(--alt-bg);
}

.abs-top-left-1rem {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.abs-lower-right {
  position: absolute;
  right: 0.3rem;
  bottom: 0.3rem;
}

.abs-lower-right-1rem {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.abs-top-left {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
}

.abs-top-right {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}

.abs-top-right-0 {
  position: absolute;
  top: 0;
  right: 0;
}

.abs-center {
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 20%;
  left: 20%;
}

.abs-center-30 {
  position: absolute;
  top: 30%;
  bottom: 30%;
  right: 30%;
  left: 30%;
}

.abs-1rem {
  position: absolute;
  top: 1rem;
}

.abs-top-center {
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
}

.z10 {
  z-index: 10;
}

.background-transparent {
  background: transparent;
}

.ppt-text-area {
  height: 100%;
  width: calc(100% - 0.8rem);
  background: var(--alt-bg);
  border: none;
  border-right: inset 0.4rem solid var(--ppt-blue);
  font-size: 1.1rem;
  line-height: 1.4;
  padding: 0.4rem;
  -webkit-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  resize: none;
  overflow-y: auto;
}

.ppt-input:valid {
  border-right: 0.4rem solid var(--ppt-blue);
}

.unique-valid::after,
.unique-invalid::after {
  display: block;
  opacity: 0.85;
  position: absolute;
  background: transparent;
  height: 1rem;
  width: 1rem;
  top: 0.5rem;
  right: 1rem;
  z-index: 10;
}

.unique-valid::after {
  content: "✅";
}

.unique-invalid::after {
  content: "❌";
}

.copy-snippet-wrapper {
  background: var(--alt-bg);
  padding: 0.3rem;
  height: 2rem;
  min-width: 10rem;
  border: 3px dashed var(--ppt-blue);
  color: var(--dark-text);
  display: flex;
  flex-wrap: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.1s;
  position: relative;
}

.copy-snippet-wrapper:hover {
  background: var(--light-bg);
  color: var(--light-text);
}

.copy-snipped-copied {
  border: 3px dashed var(--ppt-red);
}

/* .tooltip-wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  background: var(--light-bg);
  color: var(--light-text);
  min-width: 5rem;
  max-width: 10rem;
  height: 2rem;
  border: 2px solid var(--ppt-red);
  padding: 0.2rem;
  transition: 0.1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  font-size: 0.9rem;
}

.copy-snipped-copied .tooltip {
  border: 2px solid var(--ppt-blue);
}

.tooltip-wrapper:hover .tooltip {
  visibility: visible;
}

.tooltip-right {
  left: 105%;
} */

.ppt-action {
  cursor: pointer;
  background: transparent;
  min-width: 2rem;
  min-height: 2rem;
  border: none;
  margin: 0.2rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
}

.ppt-action:hover {
  background: var(--ppt-blue);
  color: var(--alt-bg);
}

.box-content,
.box-content-modal {
  -webkit-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  background: var(--light-bg);
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
}

.box-content-modal {
  position: absolute;
}

.box-content-border {
  background: var(--alt-bg);
  border: 1rem solid var(--light-bg);
  color: var(--dark-text);
}

.inset-box {
  background: var(--alt-bg);
  color: var(--dark-text);
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
  box-shadow: inset 0px 3px 28px -5px rgba(42, 98, 253, 0.432);
}

.border-5px-transparent {
  border: 5px solid transparent;
  background-clip: padding-box;
}

.border-5px-solid-blue {
  border: 5px solid var(--ppt-blue);
}

.border-5px-dashed-blue {
  border: 5px dashed var(--ppt-blue);
  background-clip: padding-box;
}

.border-attention {
  padding: 1rem;
  border: 2px solid var(--ppt-red);
}

.attention-link {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background: var(--ppt-success);
  border-radius: 0.2rem;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.attention-link:hover {
  background: var(--ppt-grey);
  color: var(--ppt-success);
}

.headline-button-secondary,
.headline-button-light,
.headline-button {
  background: none;
  color: var(--dark-text);
  font-family: var(--headline-font);
  font-weight: 700;
  font-size: 2rem;
  transition: 0.1s;
}

.headline-button:active {
  color: var(--ppt-blue);
}

.headline-button:disabled {
  color: var(--grey-text);
}

.action-button {
  background: none;
  color: var(--light-text);
  font-family: var(--headline-font);
  font-weight: 600;
  font-size: 1.4rem;
  transition: 0.1s;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.action-button:hover {
  color: var(--ppt-blue);
}

.action-button:disabled {
  color: var(--ppt-grey);
}

.small-button {
  background: none;
  color: var(--dark-text);
  font-family: var(--headline-font);
  font-weight: 600;
  font-size: 1.1rem;
  transition: 0.1s;
  text-transform: lowercase;
}

.filter-shadow {
  filter: drop-shadow(0px 4px 15px #222);
}

.small-button:hover {
  color: var(--ppt-blue);
}

.headline-button-showaction {
  color: var(--ppt-red);
}

.headline-button-secondary {
  color: var(--grey-text);
}

.headline-button-light {
  color: var(--light-text);
}

.headline-button-light:hover {
  color: var(--ppt-blue);
}

.text-align-center {
  text-align: center;
}

.alt-bg {
  background: var(--alt-bg);
}

.light-bg {
  background: var(--light-bg);
}

.dark-text {
  color: var(--dark-text);
}

.light-text {
  color: var(--light-text);
}

.blue-text {
  color: var(--ppt-blue);
}

.red-text {
  color: var(--ppt-red);
}

.green-text {
  color: var(--ppt-success);
}

.darkgreen-text {
  color: var(--ppt-darkgreen);
}

.orange-text {
  color: var(--ppt-caution);
}

.four-rem-text {
  font-size: 4rem;
  line-height: 1;
}

.three-rem-text {
  font-size: 3rem;
}

.two-rem-text {
  font-size: 2rem;
}

.ppt-cover-image {
  width: 100%;
  display: block;
  height: auto;
  object-fit: cover;
}

.box-content-center {
  width: 85%;
  margin: 1rem;
  padding: 1rem;
  min-height: 3rem;
  background: var(--alt-bg);
  color: var(--dark-bg);
  transition: 0.3s;
}

.right-action-buttons {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: var(--alt-bg);
  padding: 0.5rem 0.1rem 0.5rem 0.5rem;
}

.dark-border-light-bg {
  border-color: var(--light-bg);
  background: var(--alt-bg);
}

.hover-border-blue {
  transition: 0.1s;
  transition-timing-function: ease-in-out;
}

.hover-border-dashed {
  border-style: dashed;
}

.hover-border-blue:hover {
  border-color: var(--ppt-blue);
  box-shadow: inset 0px 0px 35px -10px var(--ppt-blue),
    inset 0px 0px 10px -8px var(--grey-text);
}

.hide-on-collapse {
  transform-origin: top;
  transition: 0.1s;
  transition-timing-function: ease-in-out;
  opacity: 1;
  transform: scaleY(1);
}

.collapse .hide-on-collapse {
  opacity: 0;
  transform: scaleY(0);
}

.show-on-collapse {
  display: none;
  transition: 0.1s;
  transition-timing-function: ease-in-out;
  opacity: 0;
}

.outside-link {
  color: var(--ppt-blue);
  font-family: var(--headline-font);
  font-size: 1.1rem;
  cursor: pointer;
}

.collapse .show-on-collapse {
  display: inherit;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2rem;
  left: 2rem;
}

.fade-in-bck-center {
  -webkit-animation: fade-in-bck 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: fade-in-bck 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.exit-form #login-credentials,
.exit-form #signup-information {
  animation: login-main-out 0.4s ease-in-out forwards;
}

.exit-form #login-actions,
.exit-form #signup-actions {
  animation: shift-login-actions-left 0.2s ease-in-out forwards;
}

.glass {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background: inherit;
  overflow: hidden;
  z-index: 1;
}

.glass::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.borderbox {
  box-sizing: border-box;
}

.overflow-visible {
  overflow: visible;
}

@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-out-bck {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
}
@keyframes fade-out-bck {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
}

@keyframes shift-login-actions-left {
  0% {
    transform: translateX(-2rem);
    opacity: 1;
  }
  100% {
    transform: translateX(-16rem);
    opacity: 0;
  }
}

@-webkit-keyframes shift-login-actions-left {
  0% {
    -webkit-transform: translateX(-2rem);
    transform: translateX(-2rem);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-16rem);
    transform: translateX(-16rem);
    opacity: 0;
  }
}

@keyframes login-main-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(8rem);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(8rem);

    opacity: 0;
  }
}

@-webkit-keyframes login-main-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(8rem);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(8rem);
    opacity: 0;
  }
}

.slide-in-bck-center {
  -webkit-animation: slide-in-bck-center 0.3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: slide-in-bck-center 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.slide-out-bck-center {
  -webkit-animation: slide-out-bck-center 0.3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: slide-out-bck-center 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-1-15 11:41:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0;
  }
}

.dot-spinner {
  margin: 0.3rem;
  width: 3rem;
  height: 3rem;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 50%;
  height: 50%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--ppt-blue);
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: var(--ppt-red);
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

#twilio-connect-button {
  background: url(https://www.twilio.com/bundles/connect-apps/img/connect-button.png);
  width: 130px;
  height: 34px;
  display: block;
  margin: 0.5rem;
}
#twilio-connect-button:hover {
  background-position: 0 34px;
}

.app-layout {
  @apply w-full h-full flex flex-row items-start justify-between;
}
.app-layout-content {
  @apply w-10/12 overflow-y-auto max-h-full h-screen-calc;
}
.app-layout-nav {
  @apply w-2/12 flex flex-col items-center justify-start;
}

.hover-scale {
  transition: 0.15s;
  transition-timing-function: ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.03);
  /* box-shadow: 0px 5px 15px -3px var(--dark-bg); */
}

.h-screen-calc {
  height: calc(100vh - 6rem);
}

.hero-background {
  background: var(--primary-brand-color) url("/hero-squashed.jpeg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  color: var(--text-color-contrast);
}

.hero-background::before {
  opacity: 0.85;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: " ";
  z-index: -1;
}

.fixed-top-center {
  position: fixed;
  top: 2rem;
  width: 22rem;
  left: 50%;
  margin-left: -11rem;
}

.abs-cover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.top-8 {
  top: 2rem;
}

.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

.react-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.bullet::before {
  content: "◆ ";
  color: inherit;
}

.height-50 {
  height: 50%;
}

@import "./listing.css";
@import "./dropzone.css";
@import "./leadMagnets.css";
@import "./themes.css";
